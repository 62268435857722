// 土壤墒情
const selfData = {
    loading: false,
    snTable: {

        table: {
            data: [],
            rowKey: 'ID',
        },
        columns: {
            columnsData: [{
                label: "年份",
                prop: "NF",
                minWidth: 120,
                sortable: 'custom',
            }, {
                label: "月份",
                prop: "YF",
                sortable: 'custom',
            }, {
                label: "温度(℃)",
                prop: "DYQW"
            },
            ],
            // 操作列
            columnsBtn: {
                buttonData: []
            }
        },
    },
    snSearch: {
        inputData: [{
            label: '关键字',
            placeholder: "请输入关键字",
            value: 'keyword',
            operateType: 'search',
            isShow: true,
            default: ""
        }],
        dateData : [{
            isShow: true,
            label: '开始时间',
            clearable: true,
            placeholder: '开始时间',
            operateType: 'search',
            value: 'start',
        },{
            isShow: true,
            label: '结束时间',
            clearable: true,
            placeholder: '结束时间',
            operateType: 'search',
            value: 'end',
        }]
    },
    snButton: {
        buttonData: [
            {
                isShow: true,
                btnType: 'button',
                operateType: 'buttonNew',
                showMethod: 'showNewBtn',
                name: '新建',
                round: true,
                backColor: '#28ccd9',
            }, {
                isShow: true,
                btnType: 'button',
                operateType: 'buttonLoad',
                name: '模板下载',
                round: true,
                backColor: '#ff669c',
            }, {
                isShow: true,
                btnType: 'upload',
                accept: "xlsx,xls",
                // buttonImport
                operateType: '',
                name: '导入',
                round: true,
                backColor: '#2888d9',
            },
            {
                btnType: 'button',
                operateType: 'buttonExport',
                showMethod: 'showExportBtn',
                name: '导出',
                round: true,
                backColor: '#ffcc66',
                color: '#fff'
            }
        ]
    }
};
export default selfData;